@import '~@alatimier/genesis-uic/src/styles/genesis-uic';

#filtersReminderContainer {
	position: fixed;
	top: $header-min-height;
	right: 0;
	max-width: 40%;
	z-index: 1000;

	.card {
		overflow: hidden;
		transition: max-height 0.4s;
		max-height: 9.8em;
		&.hidden {
			max-height: 0;
		}

		.GCardContent {
			overflow: auto;
			overflow: overlay; // Don't change container width during max-height animation on WebKit and Blink navigators

			&::-webkit-scrollbar {
				width: 5px;
			}
			&::-webkit-scrollbar-thumb {
				border-radius: 5px;
			}

			// Use border color to make scrollbar appear smoothly
			border-color: rgba(0, 0, 0, 0);
			&:not(.collapsing) {
				// Don't prevent collapsing height transition
				transition: border-color 0.4s;
			}
			&:hover {
				border-color: rgba(0, 0, 0, 0.1);
			}
			&::-webkit-scrollbar,
			&::-webkit-scrollbar-thumb,
			&::-webkit-scrollbar-corner {
				border-right-style: inset;
				border-right-width: 5px;
				border-color: inherit;
			}
		}

		.card-header,
		.card-body {
			padding-top: 0.5em;
			padding-bottom: 0.5em;
		}

		// Don't change card width when collapsing it, stay as wide as content
		.collapse:not(.show) {
			height: 0;
			display: block;
		}
	}
}
