.editor {
	$leftPanelSize: 25%;
	$rightPanelSize: 16%;
	$zoomHeight: 5%;

	margin-bottom: 0 !important;

	#topBar {
		.tabIcon {
			width: 2.6em;
		}
		button#save {
			margin: 0 0.6em 0 0.8em;
		}
	}

	.card-body {
		padding: 0;
		background-color: var(--cui-card-bg, snow);

		#editorView {
			width: calc(100% - #{$leftPanelSize} - #{$rightPanelSize});
			height: calc(100vh - 17rem);
			min-height: 300px;
			margin-left: $leftPanelSize;
			margin-right: $rightPanelSize;
			background-color: var(--cui-dark-start, gainsboro);

			#workarea {
				width: 100%;
				height: calc(100% - #{$zoomHeight});
				box-shadow: 0 0 30px rgba(0, 0, 0, 0.08);
				overflow: auto;
				// Make scrollbar not take any width to prevent horizontal scrollbar (on WebKit and Blink navigators only)
				overflow: overlay;

				border-color: darkgrey;
				&::-webkit-scrollbar,
				&::-webkit-scrollbar-thumb {
					border-color: inherit;
					&:horizontal {
						border-bottom-style: inset;
						border-bottom-width: 10px;
					}
					&:vertical {
						border-right-style: inset;
						border-right-width: 10px;
					}
				}
				&::-webkit-scrollbar {
					border-color: var(--cui-dark-stop, silver);
				}
				&::-webkit-scrollbar-corner {
					border-color: var(--cui-dark-stop, silver);
					border-bottom-style: inset;
					border-right-style: inset;
					border-bottom-width: 10px;
					border-right-width: 10px;
				}

				#svgcanvas {
					&.mode-panning {
						cursor: grab;
					}
					&.mode-text {
						cursor: text;
					}
					#canvasBackground rect {
						stroke: rgba(0, 0, 0, 0.8);
					}
					&.mode-select .movable,
					&.mode-multiselect .movable {
						cursor: move;
					}
				}

				.contextMenu {
					min-width: 15rem;
					padding: 0.3rem 0;
					button {
						padding: 0.3rem 1rem;
					}
				}
			}

			.zoom {
				height: $zoomHeight;
				display: flex;
				align-items: center;
				background-color: var(--cui-card-bg);
				i {
					transform: scaleX(-1);
				}
				.range-wrap {
					margin: 0 1rem;
				}
			}
		}

		.leftPanel,
		.rightPanel {
			background-color: var(--cui-card-bg);
			box-shadow: 0 0 30px rgba(0, 0, 0, 0.08);
		}

		.leftPanel {
			width: $leftPanelSize;
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			> div:first-child {
				flex: 1;
				overflow: auto;
				overflow: overlay;

				h1 {
					> span,
					i {
						vertical-align: middle;
					}
					button {
						cursor: auto;
					}
				}
			}

			.generalTools {
				width: 100%;
				padding: 0.25em;
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;

				button {
					i {
						font-size: 1.5em;
					}
					&:hover i {
						color: grey;
					}
					&.active i {
						color: dimgrey;
					}
				}
			}

			.shapePanel {
				button img {
					width: 70px;
					height: 70px;
				}
			}

			.textPanel {
				#fontFamily {
					flex: 1;
				}
				#fontSize {
					width: 4.4em;
				}

				// Show user the button property is not applied if no text selected
				.btn:not(:hover):not(.active):focus {
					background-color: var(--cui-btn-bg);
				}
			}

			.upload {
				border: 1px grey dashed;
				border-radius: 0.5rem;
				text-align: center;
				&.draggedOver {
					background-color: var(--cui-bg-dark, gainsboro);
				}
			}

			h1 {
				text-transform: uppercase;
			}

			figure {
				padding: 2px;
				width: min-content;
				max-width: 150px;
				height: min-content;
				border: 1px transparent solid;
				cursor: pointer;
				&.selected {
					background-color: var(--cui-light, powderblue);
					border-color: var(--cui-bg-dark, darkgrey);
				}

				img {
					display: block;
					margin: auto;
					max-height: 200px;
					max-width: 150px;
					border: 1px var(--cui-dark-start, gainsboro) solid;
				}
				figcaption {
					text-align: center;
					text-overflow: ellipsis;
					white-space: nowrap;
					overflow: hidden;
					font-style: italic;
					input {
						font-style: italic;
						outline-color: silver;
						padding: 0;
						text-align: center;
					}
				}
			}
		}

		.rightPanel {
			width: $rightPanelSize;
			overflow: auto;
			overflow: overlay;

			.colorInput {
				display: block;
				max-width: 100%;
				&.selected {
					background-color: var(--cui-light);
				}
				> div {
					.h6 {
						margin-bottom: 0;
						overflow: hidden;
					}
				}
			}

			.colorCategory {
				display: flex;
				align-items: center;
				flex-wrap: wrap;

				.color {
					width: 25px;
					height: 25px;
					padding: 0;
					border-radius: 2px;
					margin: 6px;
					color: white;
					&.color:hover {
						margin: 3px;
						width: 31px;
						height: 31px;
						i {
							font-size: 22px;
							display: block;
							margin-top: auto;
							margin-bottom: auto;
						}
					}
				}

				.hiddenColorInput {
					visibility: hidden;
					position: absolute;
					top: 0;
				}
			}
		}

		.contextMenu {
			position: fixed;
			background-color: rgb(42, 42, 42);
			color: white;
			border: 1px grey solid;
			border-radius: 0.2em;
			font-size: 0.8em;
			font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
			box-shadow: 2px 5px 30px rgba(0, 0, 0, 0.3);
			button {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 0.3rem 0.5rem;
				border-radius: 0.2em;
				background-color: unset;
				color: unset;
				border: none;
				width: 100%;
				&[disabled] {
					color: grey;
				}
				&:not([disabled]):hover {
					background-color: rgb(70, 70, 70);
				}
				.fas {
					line-height: inherit;
				}
			}
		}

		input[type='range'] {
			&::-webkit-slider-runnable-track {
				background-color: var(--cui-dark-start, var(--cui-gray-300));
			}
			&::-webkit-slider-thumb {
				background-color: var(--cui-primary);
			}
		}
	}
}
