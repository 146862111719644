@import '~@alatimier/genesis-uic/src/styles/genesis-uic';

@font-face {
	font-family: 'Andale Mono';
	src: local('Andale Mono'), url('../utils/fonts/andalemono.ttf');
}

@font-face {
	font-family: 'Roboto';
	src: local('Roboto Condensed - Regular'), url('../utils/fonts/RobotoCondensed-Regular.ttf');
}
@font-face {
	font-family: 'Roboto';
	font-weight: bold;
	src: local('Roboto Condensed - Bold'), url('../utils/fonts/RobotoCondensed-Bold.ttf');
}
@font-face {
	font-family: 'Roboto';
	font-weight: bold;
	font-style: italic;
	src: local('Roboto Condensed - Bold Italic'), url('../utils/fonts/RobotoCondensed-BoldItalic.ttf');
}
@font-face {
	font-family: 'Roboto';
	font-style: italic;
	src: local('Roboto Condensed - Italic'), url('../utils/fonts/RobotoCondensed-Italic.ttf');
}
@font-face {
	font-family: 'Roboto Light';
	src: local('Roboto Condensed - Light'), url('../utils/fonts/RobotoCondensed-Light.ttf');
}
@font-face {
	font-family: 'Roboto Light';
	font-style: italic;
	src: local('Roboto Condensed - Light Italic'), url('../utils/fonts/RobotoCondensed-LightItalic.ttf');
}

/** Larger tooltips for mathematical formulas (TradeMarketingChart) */
.tooltip .tooltip-inner {
	max-width: 400px;
}
