.dark-theme {
	.table-secondary:not([class*='dark:']) {
		--cui-table-bg: #373737;
		--cui-table-color: rgba(255, 255, 255, 0.87);
		--cui-table-border-color: rgba(255, 255, 255, 0.075);
		--cui-table-striped-color: rgba(255, 255, 255, 0.87);
		--cui-table-striped-bg: rgba(255, 255, 255, 0.05);
		--cui-table-active-color: rgba(255, 255, 255, 0.87);
		--cui-table-active-bg: rgba(0, 0, 21, 0.1);
		--cui-table-hover-color: rgba(255, 255, 255, 0.87);
		--cui-table-hover-bg: rgba(0, 0, 21, 0.075);
	}
	.table-success:not([class*='dark:']) {
		--cui-table-bg: #013608;
		--cui-table-color: rgba(255, 255, 255, 0.87);
		--cui-table-border-color: rgba(255, 255, 255, 0.075);
		--cui-table-striped-color: rgba(255, 255, 255, 0.87);
		--cui-table-striped-bg: rgba(255, 255, 255, 0.05);
		--cui-table-active-color: rgba(255, 255, 255, 0.87);
		--cui-table-active-bg: rgba(0, 0, 21, 0.1);
		--cui-table-hover-color: rgba(255, 255, 255, 0.87);
		--cui-table-hover-bg: rgba(0, 0, 21, 0.075);
	}
	.table-danger:not([class*='dark:']) {
		--cui-table-bg: #471111;
		--cui-table-color: rgba(255, 255, 255, 0.87);
		--cui-table-border-color: rgba(255, 255, 255, 0.075);
		--cui-table-striped-color: rgba(255, 255, 255, 0.87);
		--cui-table-striped-bg: rgba(255, 255, 255, 0.05);
		--cui-table-active-color: rgba(255, 255, 255, 0.87);
		--cui-table-active-bg: rgba(0, 0, 21, 0.1);
		--cui-table-hover-color: rgba(255, 255, 255, 0.87);
		--cui-table-hover-bg: rgba(0, 0, 21, 0.075);
	}
}

.table-overflow-visible {
	.table-responsive {
		overflow: visible;
	}
}
